import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"


const Content = () => (
  <Layout>
    <Helmet>
      <title>Privacy policy | IBDmate</title>
    </Helmet>
  <div className="layout-content">
    <div className="blue-bg">
      <form>
        <h1>Privacy policy</h1>
      </form>
      <div className="vectors-left">
        <img src={BlueDots} id="blue-dots" alt="" />
        <img src={BlueDot} id="blue-dot" alt="" />
      </div>
      <div className="vectors-right">
        <img src={BlueCicle} id="blue-circle" alt="" />
        <img src={BlueDots2} id="blue-dots-2" alt="" />
      </div>
    </div>
    <div className="wrapper content">
      <h2>Introduction</h2>
      <p>Welcome to IBD Relief Ltd’s privacy notice.</p>
      <p>IBDrelief respects your privacy and is committed to protecting your personal data. This privacy notice will inform you about how we look after your personal data when you visit our website and use our services (regardless of where you visit it from) and tell you about your privacy rights and how the law protects you.</p>
      <p>This privacy notice is provided in a layered format so you can click through to the specific areas set out below. Please also use the <a href="#glossary">Glossary</a> to understand the meaning of some of the terms used in this privacy notice.</p>
      <ol>
        <li><a href="#who">Important information and who we are</a></li>
        <li><a href="#data">The data we collect about</a></li>
        <li><a href="#personal">How is your personal data collected?</a></li>
        <li><a href="#use">How we use your personal data</a></li>
        <li><a href="#disclosures">Disclosures of your personal data</a></li>
        <li><a href="#international">International transfers</a></li>
        <li><a href="#security">Data security</a></li>
        <li><a href="#retention">Data retention</a></li>
        <li><a href="#legal">Your legal rights</a></li>
        <li><a href="#glossary">Glossary</a></li>
      </ol>
      <h2 id="who">Important information and who we are</h2>
      <h3>Purpose of this privacy notice</h3>
      <p>This privacy notice aims to give you information about how IBDrelief collects and processes your personal data through your use of its website, including any data you may provide when you register via our website and make use of any of the services we offer, including our online educational courses.</p>
      <p>Personal data is any information about you which identifies you, or from which you could be identified. You have a number of rights concerning the use of your personal data which are set out in this notice.</p>
      <p>A child is any person under the age of 18. Our website is accessible to both children and adults. We therefore collect personal data relating to children who use our website and the services we offer. We aim to protect and support children’s health and wellbeing and recognise the role of parents in protecting and promoting the best interests of any child who uses our website. Our aim is to ensure that our website and the services accessed by children are appropriate for their use and meet their development needs. We need to process children’s personal data to help achieve this.</p>
      <p>It is important that you read this privacy notice together with any other privacy notice or fair processing notice we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy notice supplements other notices and privacy policies and is not intended to override them.</p>
      <h3>Controller</h3>
      <p>IBD Relief Ltd, a company trading at 16-18 East Street, Shoreham-By-Sea, West Sussex, United Kingdom, BN43 5ZE and registered in England (company number 09661610) whose registered office is at the same trading address, is the controller and responsible for your personal data (collectively referred to as IBDrelief, "we", "us" or "our" in this privacy notice).</p>
      <p>We have appointed a data protection officer (DPO) who is responsible for overseeing questions in relation to this privacy notice. If you have any questions about this privacy notice, including any requests to exercise your legal rights, please contact the DPO using the details set out below.</p>
      <h3>Contact details</h3>
      <p>If you have any questions about this privacy notice or our privacy practices, please contact our DPO in the following ways:</p>
      <ul>
        <li>Full name of legal entity: IBD Relief Limited </li>
        <li>Email address: <a href="mailto:info@ibdrelief.com">info@ibdrelief.com</a></li>
        <li>Postal address: 16-18 East Street, Shoreham-By-Sea, West Sussex, United Kingdom, BN43 5ZE</li>
        <li>Telephone number: 01273 446644</li>
      </ul>
      <p>You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK supervisory authority for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.</p>
      <h3>Changes to the privacy notice and your duty to inform us of changes</h3>
      <p>We keep our privacy notice under regular review. This version was last updated on 27th Feb 2021. Previous versions can be obtained by contacting us.</p>
      <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>
      <h3>Third-party links</h3>
      <p>Our website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy notices. When you leave our website, you should consider reading the privacy notice of other websites you visit.</p>
      <h2 id="data">2. The data we collect about you</h2>
      <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
      <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
      <ul>
        <li><strong>Identity Data</strong> includes forename, last name, username or similar identifier, title, date of birth and gender.</li>
        <li><strong>Contact Data</strong> includes your address, email address, telephone numbers, and social media accounts</li>
        <li><strong>Health Data</strong> includes information related to your physical or mental health, including information obtained through your use of our e-learning service which may reveal information about your health status such as symptoms, diagnosis date, your diagnosis, details of surgeries, details of treatments</li>
        <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our website and services.</li>
        <li><strong>Profile Data</strong> includes your username and password, name and contact information, services used by you, e-learning courses completed by you, story you provide to us, photographs, status updates on your account, your interests, preferences, feedback and survey responses.</li>
        <li><strong>Usage Data</strong> includes information about how you use our website and services.</li>
        <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and our third parties and your communication preferences.</li>
      </ul>
      <p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific service on our website. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy notice.</p>
      <p>Due to the nature of the services we provide, it is necessary for us to collect <strong>Special Categories of Personal Data</strong> about you (this includes information about your health, details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, genetic and biometric data) but only where this is strictly necessary for the purposes of providing you with our services. We do not collect any information about criminal convictions and offences.</p>
      <h3>If you fail to provide personal data</h3>
      <p>Where we need to collect personal data by law, or under the terms of a contract we have with you, and you fail to provide that data when requested, we may not be able to provide you with the services offered on our website. In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time.</p>
      <h2 id="personal">3. How is your personal data collected?</h2>
      <p>We use different methods to collect data from and about you including through:</p>
      <ul>
        <li><strong>Direct interactions.</strong> You may give us your Identity, Contact and Health Data by using our website, including by registering and creating a profile, or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:
          <ul>
            <li>register with us via our website;</li>
            <li>create an online profile on our website;</li>
            <li>request that we provide a service to you;</li>
            <li>participate in our e-learning courses;</li>
            <li>complete a survey on our website;</li>
            <li>request information (including marketing information) to be sent to you; or</li>
            <li>give us feedback or contact us.</li>
          </ul>
        </li>
        <li><strong>Automated technologies or interactions.</strong> As you interact with our website, we will automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies and other similar technologies. Please see our cookie policy [LINK] for further details.</li>
        <li><strong>Third parties.</strong> We may receive personal data about you from various third parties as set out below:
          <ul>
            <li>A referring healthcare professional;</li>
            <li>Technical Data from the following parties:
              <ul>
                <li>Companies analysing traffic on our website</li>
                <li>analytics providers such as Google Analytics;</li>
                <li>advertising networks such as Google Adsense;</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h2 id="use">4. How we use your personal data</h2>
      <p>We will only process your personal data for purposes in accordance with the explicit consent you have provided, as set out in our form of consent.</p>
      <p>Only children over the age of 13 and who are competent to provide consent are legally able to provide their own consent to the processing of their personal data. If a child is under this age, or above this age but not considered competent to provide consent, we must legally obtain the consent of their parent or the person who has parental responsibility for the child to the processing of the child’s personal data. Verification of the child’s age and of the identity of the adult providing consent on behalf of the child will be required.</p>
      <p>We may also use your personal data in the following circumstances:</p>
      <ul>
        <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</li>
        <li>Where the law requires us to use your personal data in order to carry on our business;</li>
        <li>Where we are subject to another legal obligation to process your personal data.</li>
      </ul>
      <p><a href="glossary">Click here</a> to find out more about the types of lawful basis that we will rely on to process your personal data.</p>
      <p>We will obtain your consent before sending third party direct marketing communications to you via email or text message. You have the right to withdraw consent to marketing at any time by contacting us or unsubscribing via the link in our emails. Marketing communications will not be sent directly to children.</p>
      <p>Shared data provided to us is viewable by other members of the website who are signed into their account through profiles and aggregated reports. This may include profile name, personal bio, any comments left on our website, location, hospital, age, disease, medication taken, surgery you've had, type of IBD. These may be viewable on your profile or in comments left. Some of this information is also available to be viewed by non-members who use the website. Since IBDrelief is a public internet community, by using our website and our services you accept that any information that you add through free text (such as in article comments or in your personal biography) or images becomes shared data.</p>
      <h3>Purposes for which we will use your personal data</h3>
      <p>We have set out below, in a table format, a description of all the ways we plan to use your personal data, with your consent.</p>
      <p>If you make a request we may need to use your personal data for the purposes of fulfilling that request, such as requesting to receive a newsletter via email, requesting an email response from our support team, or requesting a product be sent in the post to your address.  By making the request you consent to the use of your data for these purposes.</p>
      <p>Note that we will only process your personal data on the basis of the explicit consent you have provided for which we may use your data. Please contact us if you need details about this specific legal ground we rely on to process your personal data.</p>
      <div className="table">
        <table>
          <thead>
            <tr>
              <th>Purpose/Activity</th>
              <th>Type of data</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>To register you on our website and create your online profile with us.</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To process and deliver your request for educational services, including our e-learning courses.</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Health</li>
                  <li>(d) Profile</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>In circumstances where you  have chosen not to register via our website, but use our educational services, our website will capture certain details about you through the use of cookies. For example, we will be able to track which courses you have completed and any information, including health data, you have inputted during completion of the course(s).</td>
              <td>
                <ul>
                  <li>(a) Health</li>
                  <li>(b) Technical</li>
                  <li>(c) Usage</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Where you have been referred to our website and use of our services by a healthcare professional, we will generate a code for the healthcare professional to supply to you. You may then input the code into our website to gain access to our e-learning courses. The referring healthcare professional will be provided with information about your activities on our website, including the e-learning courses you have accessed and completed.</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Health</li>
                  <li>(d) Technical</li>
                  <li>(e) Profile</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To communicate with you about IBDrelief, the services we can provide you with and the e-learning courses which may be of interest to you.   We will not contact children for this purpose without explicit consent from their parent or other person with parental responsibility, but we may contact the child’s parent or person with parental responsibility for this purpose if they consent to this.</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To manage our relationship with you which will include:
                <ul>
                  <li>(a) Notifying you about changes to our terms or privacy notice</li>
                  <li>(b) Asking you to leave a review or take a survey (such requests will not be sent to children who use our website subject to (c))</li>
                  <li>(c) If a child under the age of 13 is signed up to our website, we may, from time to time, send the child’s parent or person with parental responsibility a survey to complete, subject to their consent. This may be completed with the child’s input should the parent/person with parental responsibility wish.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Profile</li>
                  <li>(d) Marketing and Communications</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To administer and protect our business and website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Technical</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Allow third parties within the European Economic Area access to our website to analyse traffic, including Google.</td>
              <td></td>
            </tr>
            <tr>
              <td>To deliver relevant website content to you</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Profile</li>
                  <li>(d) Usage</li>
                  <li>(e) Marketing and Communications</li>
                  <li>(f) Technical</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To use data analytics to improve our website, services, marketing, client relationships and experiences</td>
              <td>
                <ul>
                  <li>(a) Technical</li>
                  <li>(b) Usage</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>To make suggestions and recommendations to you about goods or services that may be of interest to you</td>
              <td>
                <ul>
                  <li>(a) Identity</li>
                  <li>(b) Contact</li>
                  <li>(c) Technical</li>
                  <li>(d) Usage</li>
                  <li>(e) Profile</li>
                  <li>(f) Marketing and Communications</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h3>Marketing</h3>
      <p>We want to provide you with choices regarding certain uses of your personal data, particularly where this is for the purposes of marketing and advertising.  When a child is signed up to use our services, we will send email communications, such as our Mailchimp newsletter,  to the child’s parent or person with parental responsibility if consent to this has been provided. We may send surveys to complete if consented to.</p>
      <h3>Promoting content</h3>
      <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which services and offers may be relevant for you (we call this marketing).</p>
      <p>You will receive marketing communications from us if you have requested information from us, registered with us via our website and/or used our educational services and you have not opted out of receiving that marketing.</p>
      <h3>Third-party marketing </h3>
      <p>We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.</p>
      <h3>Opting out</h3>
      <p>You can ask us or third parties to stop sending you marketing messages at any time by accessing the website and checking or unchecking relevant boxes to adjust your marketing preferences, by following the opt-out links on any marketing message sent to you, or by contacting us at any time.</p>
      <p>Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of your use of our website and educational services and when you register with us via our website.</p>
      <h3>Cookies</h3>
      <p>You can set your browser or device to refuse all or some cookies, or to alert you when websites or apps set or access cookies. If you disable or refuse cookies, please note that some parts of our website may become inaccessible or not function properly. For more information about the cookies we use, please see our <Link to="/cookie-policy/">cookie policy</Link>.</p>
      <h3>Change of purpose </h3>
      <p>We will only use your personal data for the purposes for which you have provided consent.</p>
      <p>If we need to use your personal data for an unrelated purpose, we will notify you and obtain your further consent for these purposes.  In particular, we will contact you where we are considering making personal data available to third parties and will always seek your consent to do so.</p>
      <p>Please note that we may process your personal data without your knowledge or consent where this is required or permitted by law.</p>
      <h2 id="disclosures">5. Disclosures of your personal data</h2>
      <p>We may wish to work with carefully selected partners in the future to help improve the lives of those living with IBD, develop our website and the services we provide.  Our partners could include providers of health services, or pharmaceutical and biotech companies.  If we are considering sharing your identifiable personal data with third parties, we will always contact you in advance and seek your consent.</p>
      <p>We may share your personal data with the parties set out below for the purposes set out in the table [Purposes for which we will use your personal data] above.</p>
      <ul>
        <li>External Third Parties as set out in the <a href="#glossary">Glossary</a>.</li>
        <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy notice.</li>
      </ul>
      <p>We may provide your anonymised data to third parties, such as pharmaceutical companies, for the purposes of market insight and to assist with health related research. We may also contact you to ask you to complete a survey for these purposes, for which your identifying person data will not be requested.
      We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
      <h2 id="international">6. International transfers</h2>
      <p>We do not transfer your personal data outside the United Kingdom or the European Economic Area (EEA).</p>
      <h2 id="security">7. Data security</h2>
      <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors, healthcare professionals, and other third parties who have a business need to know. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</p>
      <h2 id="retention">8. Data retention</h2>
      <h3>How long will you use my personal data for?</h3>
      <p>We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.</p>
      <p>To determine the appropriate retention period for personal data, we regularly consider the amount, nature and sensitivity of the personal data we hold, the potential risk of harm from unauthorised use or disclosure of personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.</p>
      <p>In some circumstances you can ask us to delete your data: see [your legal rights] below for further information.</p>
      <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.  We may also sell your anonymised personal data to pharmaceutical companies for the purpose of market insight and insight into patient trends.</p>
      <h2 id="legal">9. Your legal rights</h2>
      <p>Under certain circumstances, you have rights under data protection laws in relation to your personal data.<br /> These are:</p>
      <ul>
        <li>Request access to your personal data, known as a subject access request.</li>
        <li>Request correction of your personal data.</li>
        <li>Request erasure of your personal data.</li>
        <li>Object to processing of your personal data.</li>
        <li>Request restriction of processing your personal data.</li>
        <li>Request transfer of your personal data.</li>
        <li>Right to withdraw consent, where your personal data is processed on this basis.</li>
      </ul>
      <p>Children may exercise their data protection rights themselves if they are competent to do so. If a child does not have the competence to exercise their rights themselves, their parent or a person with parental responsibility may exercise these rights on the child’s behalf when the child has authorised that adult to do so, or when this is evidently in the best interests of that child.</p>
      <p>If you wish to exercise any of the rights set out above, please contact: <a href="mailto:info@ibdrelief.com">info@ibdrelief.com</a></p>
      <h3>No fee usually required</h3>
      <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.</p>
      <h3>What we may need from you</h3>
      <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>
      <h3>Time limit to respond</h3>
      <p>We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
      <h2 id="glossary">10. Glossary</h2>
      <h3>Lawful basis</h3>
      <p><strong>Consent</strong> means where you have provided explicit consent to the use of your personal data, including Special Categories of Personal Data, to be processed for particular purposes, as set out above.</p>
      <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
      <p><strong>Comply with a legal obligation</strong> means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.</p>
      <h3>Third parties</h3>
      <p><strong>Third Parties</strong></p>
      <ul>
        <li>Companies we use to analyse traffic on our website;</li>
        <li>Referring healthcare professionals, including General Practitioners and treating doctors; </li>
        <li>Service providers, including subcontractors based in the United Kingdom acting as processors who provide system development services and film and video services;</li>
        <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in the United Kingdom who provide consultancy, banking, legal, insurance and accounting services;</li>
        <li>Public authorities acting as processors or controllers based in the United Kingdom who require reporting of processing activities in certain circumstances;</li>
        <li>Market research/analyst services; and</li>
        <li>Pharmaceutical companies to whom we may sell anonymised data.</li>
      </ul>
      <h3>Your legal rights</h3>
      <p>You have the right to:</p>
      <p><strong>Request access</strong> to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p>
      <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have incomplete or inaccurate data we hold about you corrected.  We may need to verify the accuracy of the new data you provide to us and in the case of health data it will not always be possible to comply with your correction request.</p>
      <p><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.  If a child’s parent or person with parental responsibility for that child requests the erasure of that child’s personal data we will always consider the wishes of a competent child before accepting the request.  We will always consider the child’s best interests when any request for erasure of their personal data is made.</p>
      <p><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which means you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</p>
      <p><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</p>
      <ul>
        <li>If you want us to establish the data's accuracy.</li>
        <li>Where our use of the data is unlawful but you do not want us to erase it.</li>
        <li>Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</li>
        <li>You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
      </ul>
      <p><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</p>
      <p><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent.</p>
    </div>
    </div>
  </Layout>
)

export default Content
